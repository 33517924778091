<template>
<div>

    <router-view></router-view>

</div>
</template>

<script>
export default {
    name: 'config'
}
</script>

<style>
.legend-title {
    /*font-size: 15px;*/
    /*font-weight: normal;*/
}
</style>
